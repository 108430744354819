@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:wght@700";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Lato, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3964ff80;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-full {
  bottom: 100%;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-full {
  top: 100%;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-overlay {
  z-index: 5000;
}

.z-popover {
  z-index: 3000;
}

.m-0 {
  margin: 0;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.-mb-0 {
  margin-bottom: 0;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mt-0 {
  margin-top: 0;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-\[32px\] {
  margin-top: -32px;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0 {
  margin-right: 0;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[20vh\] {
  margin-top: 20vh;
}

.mt-auto {
  margin-top: auto;
}

.mt-px {
  margin-top: 1px;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[80px\] {
  height: 80px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-60 {
  max-height: 15rem;
}

.min-h-\[50vh\] {
  min-height: 50vh;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.\!w-\[40px\] {
  width: 40px !important;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[100px\] {
  width: 100px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[150px\] {
  width: 150px;
}

.w-\[180px\] {
  width: 180px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[900px\] {
  width: 900px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-\[150px\] {
  min-width: 150px;
}

.min-w-\[220px\] {
  min-width: 220px;
}

.min-w-\[8rem\] {
  min-width: 8rem;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[1350px\] {
  max-width: 1350px;
}

.max-w-\[80\%\] {
  max-width: 80%;
}

.max-w-full {
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[20px\] {
  --tw-translate-x: 20px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\!cursor-default {
  cursor: default !important;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-user-select: text;
  user-select: text;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-4 {
  gap: 1rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-dashed {
  border-style: dashed;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(57 100 255 / var(--tw-border-opacity));
}

.border-purpleblue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(233 227 255 / var(--tw-border-opacity));
}

.border-purpleblue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(67 24 255 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(227 26 26 / var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(163 13 39 / var(--tw-border-opacity));
}

.border-secondarygray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(224 229 242 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.bg-atv-outback {
  --tw-bg-opacity: 1;
  background-color: rgb(240 108 54 / var(--tw-bg-opacity));
}

.bg-atv-outback\/\[0\.15\] {
  background-color: #f06c3626;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-fhq-coal {
  --tw-bg-opacity: 1;
  background-color: rgb(27 27 27 / var(--tw-bg-opacity));
}

.bg-fhq-coal\/\[0\.15\] {
  background-color: #1b1b1b26;
}

.bg-mo-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(27 117 188 / var(--tw-bg-opacity));
}

.bg-mo-blue\/\[0\.15\] {
  background-color: #1b75bc26;
}

.bg-mx-red {
  --tw-bg-opacity: 1;
  background-color: rgb(223 28 55 / var(--tw-bg-opacity));
}

.bg-mx-red\/\[0\.15\] {
  background-color: #df1c3726;
}

.bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 246 218 / var(--tw-bg-opacity));
}

.bg-primarygreen-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 181 116 / var(--tw-bg-opacity));
}

.bg-purpleblue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 24 255 / var(--tw-bg-opacity));
}

.bg-purpleblue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 4 122 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(227 26 26 / var(--tw-bg-opacity));
}

.bg-secondarygray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 254 / var(--tw-bg-opacity));
}

.bg-secondarygray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 229 242 / var(--tw-bg-opacity));
}

.bg-secondarygray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(43 54 116 / var(--tw-bg-opacity));
}

.bg-tinted-700 {
  background-color: #000000b3;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-0 {
  --tw-bg-opacity: 0;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.object-contain {
  object-fit: contain;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[2px\] {
  padding: 2px;
}

.\!py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-poppins {
  font-family: Poppins, sans-serif;
}

.font-sans {
  font-family: Lato, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-2xs {
  font-size: .625rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-none {
  line-height: 1;
}

.\!text-secondarygray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(163 174 208 / var(--tw-text-opacity)) !important;
}

.text-atv-outback {
  --tw-text-opacity: 1;
  color: rgb(240 108 54 / var(--tw-text-opacity));
}

.text-deeporange-500 {
  --tw-text-opacity: 1;
  color: rgb(255 114 36 / var(--tw-text-opacity));
}

.text-fhq-coal {
  --tw-text-opacity: 1;
  color: rgb(27 27 27 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-lightblue-500 {
  --tw-text-opacity: 1;
  color: rgb(57 183 255 / var(--tw-text-opacity));
}

.text-mo-blue {
  --tw-text-opacity: 1;
  color: rgb(27 117 188 / var(--tw-text-opacity));
}

.text-mx-red {
  --tw-text-opacity: 1;
  color: rgb(223 28 55 / var(--tw-text-opacity));
}

.text-primarygreen-500 {
  --tw-text-opacity: 1;
  color: rgb(0 181 116 / var(--tw-text-opacity));
}

.text-purpleblue-500 {
  --tw-text-opacity: 1;
  color: rgb(67 24 255 / var(--tw-text-opacity));
}

.text-purpleblue-600 {
  --tw-text-opacity: 1;
  color: rgb(51 17 219 / var(--tw-text-opacity));
}

.text-purpleblue-900 {
  --tw-text-opacity: 1;
  color: rgb(17 4 122 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(238 93 80 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(227 26 26 / var(--tw-text-opacity));
}

.text-secondarydarkgray-500 {
  --tw-text-opacity: 1;
  color: rgb(143 155 186 / var(--tw-text-opacity));
}

.text-secondarygray-500 {
  --tw-text-opacity: 1;
  color: rgb(224 229 242 / var(--tw-text-opacity));
}

.text-secondarygray-600 {
  --tw-text-opacity: 1;
  color: rgb(163 174 208 / var(--tw-text-opacity));
}

.text-secondarygray-700 {
  --tw-text-opacity: 1;
  color: rgb(112 126 174 / var(--tw-text-opacity));
}

.text-secondarygray-800 {
  --tw-text-opacity: 1;
  color: rgb(71 84 140 / var(--tw-text-opacity));
}

.text-secondarygray-900 {
  --tw-text-opacity: 1;
  color: rgb(43 54 116 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(246 138 115 / var(--tw-placeholder-opacity));
}

.accent-black {
  accent-color: #000;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur-sm {
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.button.button-sm {
  padding: .25rem .75rem;
}

.button.button-md {
  min-width: 12rem;
  padding: .75rem 1.5rem;
}

.button.button-lg {
  width: 14rem;
  min-width: 14rem;
  padding: .75rem 1.5rem;
}

.button.button-grey-o {
  --tw-border-opacity: 1;
  border-color: rgb(224 229 242 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(43 54 116 / var(--tw-text-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (hover: hover) and (pointer: fine) {
  .button.button-grey-o:hover {
    --tw-border-opacity: 1;
    border-color: rgb(43 54 116 / var(--tw-border-opacity));
  }
}

.button.button-grey-o-danger {
  --tw-border-opacity: 1;
  border-color: rgb(224 229 242 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(43 54 116 / var(--tw-text-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (hover: hover) and (pointer: fine) {
  .button.button-grey-o-danger:hover {
    --tw-border-opacity: 1;
    border-color: rgb(227 26 26 / var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgb(227 26 26 / var(--tw-text-opacity));
  }
}

.button.button-purpleblue {
  --tw-bg-opacity: 1;
  background-color: rgb(17 4 122 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (hover: hover) and (pointer: fine) {
  .button.button-purpleblue:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(25 7 147 / var(--tw-bg-opacity));
  }
}

.button.button-purpleblue-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(17 4 122 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (hover: hover) and (pointer: fine) {
  .button.button-purpleblue-danger:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(227 26 26 / var(--tw-bg-opacity));
  }
}

html, body {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(112 126 174 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  font-family: Lato, sans-serif;
}

@media (min-width: 768px) {
  html, body {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }
}

html {
  width: 100%;
  height: 100%;
}

.page {
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 100vh;
  margin-left: auto;
  padding: 0 1rem;
  display: flex;
}

@media (min-width: 1200px) {
  .page {
    width: calc(100% - 310px);
    max-width: calc(100% - 310px);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container.flush {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 768px) {
  .container.flush {
    padding-left: 15px;
    padding-right: 15px;
  }
}

mark {
  --tw-border-opacity: 1;
  border-color: rgb(67 24 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(67 24 255 / var(--tw-text-opacity));
  background-color: #0000;
  border-bottom: 1px solid;
  font-weight: 700;
}

.flickity-prev-next-button {
  border-radius: 30px !important;
  width: 25px !important;
  height: 25px !important;
}

.button {
  cursor: pointer;
  text-align: center;
  border-width: 1px;
  border-radius: 1rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.button[disabled] {
  cursor: not-allowed;
  opacity: .2;
}

input.search-field {
  --tw-bg-opacity: 1;
  background-color: rgb(244 247 254 / var(--tw-bg-opacity));
  border-color: #0000;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

input.search-field::placeholder {
  --tw-text-opacity: 1;
  color: rgb(112 126 174 / var(--tw-text-opacity));
}

input.search-field {
  border-width: 1px;
}

input.search-field.icon-left {
  padding-left: 2.5rem;
}

input.search-field:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.box-table {
  width: 100%;
}

.box-table thead {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(224 229 242 / var(--tw-border-opacity));
}

.box-table thead tr th {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(163 174 208 / var(--tw-text-opacity));
  padding: .5rem;
  font-weight: 500;
}

.box-table thead tr th:first-child {
  padding-left: 1.5rem;
}

.box-table thead tr th:last-child {
  padding-right: 1.5rem;
}

.box-table thead tr:last-child th {
  --tw-text-opacity: 1;
  color: rgb(163 174 208 / var(--tw-text-opacity));
  padding-bottom: .5rem;
}

.box-table.input-table tbody td {
  padding-top: 0;
  padding-bottom: 0;
}

.box-table tbody td {
  --tw-text-opacity: 1;
  color: rgb(43 54 116 / var(--tw-text-opacity));
  padding: .5rem;
  font-weight: 700;
}

.box-table tbody tr:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(250 252 254 / var(--tw-bg-opacity));
}

.box-table tbody tr td:first-child {
  padding-left: 1.5rem;
}

@media (min-width: 768px) {
  .box-table tbody tr td:last-child {
    padding-right: 1.5rem;
  }
}

.box-table tbody tr:first-child td {
  padding-top: 1rem;
}

.editor-copy {
  overflow-wrap: break-word;
}

.editor-copy ul, .editor-copy ol {
  margin-top: 0;
  margin-bottom: .75rem;
  padding-left: 1.25rem;
  list-style-type: disc;
}

.editor-copy ul ul, .editor-copy ul ol, .editor-copy ol ul, .editor-copy ol ol {
  margin-bottom: 0;
}

.editor-copy ol {
  list-style-type: decimal;
}

.editor-copy p {
  margin-bottom: .5rem;
}

.editor-copy p:last-child {
  margin-bottom: 0;
}

.editor-copy h4 {
  margin-bottom: .5rem;
  font-weight: 700;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-secondarygray-700::placeholder {
  --tw-text-opacity: 1;
  color: rgb(112 126 174 / var(--tw-text-opacity));
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-purpleblue-500:focus {
  outline-color: #4318ff;
}

.focus\:outline-red-500:focus {
  outline-color: #e31a1a;
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group:hover .group-hover\:bg-tinted-900 {
  background-color: #000000e6;
}

.group:hover .group-hover\:text-secondarydarkgray-700 {
  --tw-text-opacity: 1;
  color: rgb(72 85 133 / var(--tw-text-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:opacity-20:disabled {
  opacity: .2;
}

@media (hover: hover) {
  .hover\:bg-purpleblue-500:hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(67 24 255 / var(--tw-bg-opacity));
  }

  .hover\:bg-secondarygray-300:hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(244 247 254 / var(--tw-bg-opacity));
  }

  .hover\:bg-secondarygray-400:hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(233 237 247 / var(--tw-bg-opacity));
  }

  .hover\:text-purpleblue-500:hover:hover {
    --tw-text-opacity: 1;
    color: rgb(67 24 255 / var(--tw-text-opacity));
  }

  .hover\:text-red-500:hover:hover {
    --tw-text-opacity: 1;
    color: rgb(227 26 26 / var(--tw-text-opacity));
  }

  .hover\:text-red-700:hover:hover {
    --tw-text-opacity: 1;
    color: rgb(163 13 39 / var(--tw-text-opacity));
  }

  .hover\:text-secondarygray-900:hover:hover {
    --tw-text-opacity: 1;
    color: rgb(43 54 116 / var(--tw-text-opacity));
  }

  .hover\:text-white:hover:hover {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .hover\:opacity-100:hover:hover {
    opacity: 1;
  }

  .hover\:opacity-95:hover:hover {
    opacity: .95;
  }

  .hover\:shadow-lg:hover:hover {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .hover\:disabled\:bg-secondarygray-500:disabled:hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(224 229 242 / var(--tw-bg-opacity));
  }
}

@media (min-width: 768px) {
  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-\[28vh\] {
    margin-top: 28vh;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-\[140px\] {
    width: 140px;
  }

  .sm\:w-\[390px\] {
    width: 390px;
  }

  .sm\:w-\[475px\] {
    width: 475px;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:min-w-\[12rem\] {
    min-width: 12rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem;
  }

  .sm\:pt-1 {
    padding-top: .25rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (min-width: 992px) {
  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-\[250px\] {
    width: 250px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:max-w-\[400px\] {
    max-width: 400px;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1200px) {
  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }
}

/*# sourceMappingURL=index.c4b4218d.css.map */
