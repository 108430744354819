@mixin touch {
  /* smartphones, touchscreens */
  @media (hover: none) and (pointer: coarse) {
    @content;
  }
}

@mixin pointer {
  /* devices with primary input: pointer */
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover {
  @include pointer {
    &:hover {
      @content;
    }
  }
}
