html,
body {
  @apply text-secondarygray-700 bg-secondarygray-300 font-sans;
  @screen sm {
    @apply bg-white;
  }
  -webkit-font-smoothing: antialiased;
}

html {
  @apply h-full w-full;
}

.page {
  @apply ml-auto flex min-h-screen w-full flex-1 flex-col py-0 px-4 lg:py-4;

  @screen lg {
    max-width: calc(100% - 310px);
    width: calc(100% - 310px);
  }
}

.container {
  @apply mx-auto;
  $pad: 15px;
  padding-left: $pad;
  padding-right: $pad;

  &.flush {
    padding-left: 0;
    padding-right: 0;
  }

  @screen sm {
    &.flush {
      padding-left: $pad;
      padding-right: $pad;
    } // flush not viable on >= sm
  }
}

// search matched results highlight
mark {
  @apply text-purpleblue-500 border-purpleblue-500 font-bold;
  background-color: transparent;
  border-bottom: 1px solid;
}

.flickity-prev-next-button {
  border-radius: 30px !important;
  height: 25px !important;
  width: 25px !important;
}
