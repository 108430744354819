@import './mixins';

// Buttons
.button {
  @apply cursor-pointer rounded-2xl text-center text-sm font-medium transition-colors;
  border-width: 1px;

  &[disabled] {
    @apply cursor-not-allowed opacity-20;
  }

  @layer utilities {
    &.button-sm {
      @apply px-3 py-1;
    }

    &.button-md {
      @apply px-6 py-3;
      min-width: 12rem;
    }

    &.button-lg {
      @apply w-56 px-6 py-3;
      min-width: 14rem;
    }

    &.button-grey-o {
      @apply border-secondarygray-500 text-secondarygray-900 transition-all;
      @include hover {
        @apply border-secondarygray-900;
      }
    }

    &.button-grey-o-danger {
      @apply border-secondarygray-500 text-secondarygray-900 transition-all;
      @include hover {
        @apply border-red-500 text-red-500;
      }
    }

    &.button-purpleblue {
      @apply bg-purpleblue-900 text-white transition-all;
      @include hover {
        @apply bg-purpleblue-800;
      }
    }

    &.button-purpleblue-danger {
      @apply bg-purpleblue-900 text-white transition-all;
      @include hover {
        @apply bg-red-500;
      }
    }
  }
}

// Fields
// input[type='text']:not(.search-field),
// textarea,
// input[type='tel'],
// input[type='date'],
// input[type='email'],
// input[type='number'],
// input[type='month'],
// input[type='password'],
// input[type='time'],
// input[type='week'] {
//   @apply border-secondarygray-500 text-secondarygray-900 placeholder:text-secondarydarkgray-500 h-14 cursor-pointer appearance-none rounded-2xl px-6 py-2 text-sm;
//   border-width: 1px;

//   &:focus {
//     @apply outline-none;
//   }
// }

input.search-field {
  @apply bg-secondarygray-300 placeholder:text-secondarygray-700 border-transparent py-2 px-4 text-sm;
  border-width: 1px;

  &.icon-left {
    @apply pl-10;
  }

  &:focus {
    @apply outline-none;
  }
}
