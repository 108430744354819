.responsive-table {
  @apply w-full overflow-x-auto;
}

.box-table {
  @apply w-full;

  thead {
    @apply border-secondarygray-500 border-b-2;

    tr {
      th {
        @apply text-secondarygray-600 px-2 py-2 text-left font-medium;
      }

      th:first-child {
        @apply pl-6;
      }

      th:last-child {
        @apply pr-6;
      }

      &:last-child th {
        @apply text-secondarygray-600 pb-2;
      }
    }
  }

  &.input-table {
    tbody td {
      @apply py-0;
    }
  }

  tbody {
    td {
      @apply text-secondarygray-900 px-2 py-2 font-bold;
    }

    tr {
      &:nth-child(even) {
        @apply bg-secondarygray-100;
      }

      td:first-child {
        @apply pl-6;
      }

      td:last-child {
        @apply sm:pr-6;
      }
    }

    tr:first-child td {
      @apply pt-4;
    }
  }
}
