.editor-copy {
  @apply break-words;

  ul,
  ol {
    @apply mt-0 mb-3 list-disc pl-5;

    ul,
    ol {
      @apply mb-0;
    }
  }

  ol {
    @apply list-decimal;
  }

  p {
    @apply mb-2;

    &:last-child {
      @apply mb-0;
    }
  }

  h4 {
    @apply mb-2 font-bold;
  }
}
